import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'
import { Button } from '../../atoms/Button'
import { ReactComponent as FrameFourSVG } from './img/frame-4.svg'
import { ReactComponent as FrameThreeSVG } from './img/frame-3.svg'
import { ReactComponent as FrameTwoSVG } from './img/frame-2.svg'
import { Event as GAEvent } from '../../components/GA'
import { ReactComponent as LigthningSVG } from './img/ligthning.svg'
import { Text } from '../../atoms/Text'
import { Title } from '../../atoms/Title'
import { color } from '../../styles/vars/colors'
import { formatSessionTextByAmount } from '../../atoms/pluralFormatters'
import { gifteryWidgetId } from '../../pages/sertifikat-na-psihoterapiyu'
import { size } from '../../constants'

const Frame = styled(
  ({ className, amount }) =>
    ({
      1: <FrameTwoSVG className={className} />,
      2: <FrameThreeSVG className={className} />,
      3: <FrameFourSVG className={className} />
    }[amount])
)``

const frameCSS = css`
  position: absolute;
  right: -16px;
  bottom: -32px;
  z-index: 1;

  @media (max-width: ${size.lg}) {
    right: -32px;

    ${({ frameAmount }) =>
      frameAmount === 2 &&
      css`
        right: -48px;
      `}
  }

  @media (max-width: ${size.md}) {
    ${({ frameAmount }) =>
      frameAmount === 2 &&
      css`
        bottom: -24px;
        right: 8px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 3 &&
      css`
        bottom: -36px;
        right: 4px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 4 &&
      css`
        bottom: -32px;
        right: 0;
      `}
  }

  @media (max-width: ${size.sm}) {
    ${({ frameAmount }) =>
      frameAmount === 2 &&
      css`
        bottom: -24px;
        right: 16px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 3 &&
      css`
        bottom: -30px;
        right: 24px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 4 &&
      css`
        bottom: -32px;
        right: 8px;
      `}
  }

  @media (max-width: ${size.xs}) {
    ${({ frameAmount }) =>
      frameAmount === 2 &&
      css`
        bottom: -30px;
        right: -8px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 3 &&
      css`
        bottom: -36px;
        right: -8px;
      `}

    ${({ frameAmount }) =>
      frameAmount === 4 &&
      css`
        bottom: -32px;
        right: -12px;
      `}
  }
`

const CardTitle = styled(({ className, sessionAmount }) => (
  <div className={className}>
    <Title.H3>{sessionAmount ? `≈${sessionAmount}` : ''}</Title.H3>
    <Title.H4>
      {sessionAmount
        ? formatSessionTextByAmount(sessionAmount)
        : 'Гибкое количество баллов'}
    </Title.H4>
  </div>
))`
  display: flex;
  flex-direction: row;
  gap: 4px;

  ${Title.H3}, ${Title.H4} {
    color: ${color.text.pimaryBlue};
    display: flex;
    align-items: flex-end;
  }

  & > ${Title.H3} {
    @media (max-width: ${size.xs}) {
      font-size: 18px;
      line-height: 26px;
    }
  }

  & > ${Title.H4} {
    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 16px;
      line-height: 24px;
    }
  }
`

const Price = styled(Title.H3)``

export const CertificateCard = styled(
  ({
    className,
    frameAmount,
    sessionAmount,
    description,
    price,
    type,
    nominal,
    gifteryLink
  }) => (
    <div className={className}>
      <CardTitle sessionAmount={sessionAmount} />
      <Text.Average>{description}</Text.Average>
      <Price>{price}</Price>
      <Frame amount={frameAmount} />
      <Button.NewPrimary
        data-giftery-widget={gifteryWidgetId}
        data-giftery-widget-color={color.button.giftery}
        data-giftery-widget-face={nominal}
        href={gifteryLink}
        onClick={useCallback(() => {
          GAEvent.OpenCertificateForm(`${sessionAmount} ${type}`)
        }, [sessionAmount, type])}
        target="_blank"
        type="externalLink"
      >
        Купить
      </Button.NewPrimary>
    </div>
  )
)`
  position: relative;
  padding: 32px 32px 40px 32px;
  display: grid;
  grid-template-rows: 32px auto 32px 40px;
  gap: 16px;
  background-color: ${color.background};
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: ${size.lg}) {
    padding: 24px;
  }

  @media (max-width: ${size.md}) {
    grid-template-rows: 32px auto 26px 40px;
    padding: 32px;
  }

  @media (max-width: ${size.sm}) {
    grid-template-rows: 26px auto 24px 40px;
    padding: 24px;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
    gap: 8px;
  }

  & ${Text.Average} {
    z-index: 2;
    font-weight: 500;
    color: #011632;
  }

  & ${Button.NewPrimary} {
    height: 40px;
    z-index: 2;
    width: 94px;
  }

  & ${Price} {
    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & ${Frame} {
    ${frameCSS}
  }
`

export const ForeignCertificateCard = styled(
  ({ className, nominal, gifteryLink }) => (
    <div className={className}>
      <CardTitle />
      <Text.Average>
        От 2500 до 30 000, выбирайте любое количество, которое хотите подарить.
      </Text.Average>
      <Button.NewPrimary
        data-giftery-widget={gifteryWidgetId}
        data-giftery-widget-color={color.button.giftery}
        data-giftery-widget-face={nominal}
        href={gifteryLink}
        onClick={useCallback(() => {
          GAEvent.OpenCertificateForm(`other_amount`)
        }, [])}
        target="_blank"
        type="externalLink"
      >
        Купить
      </Button.NewPrimary>
    </div>
  )
)`
  position: relative;
  padding: 58px 32px 0px 32px;
  display: grid;
  grid-template-rows: 32px auto 23px 40px;
  gap: 16px;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='24' ry='24' stroke='rgb(41,99,163)' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='butt'/%3e%3c/svg%3e");
  border-radius: 24px;
  overflow: hidden;

  @media (max-width: ${size.lg}) {
    padding: 64px 32px 0px 24px;
    grid-template-rows: 32px auto 8px 40px;
  }

  @media (max-width: ${size.md}) {
    padding: 24px 32px 16px 32px;
    grid-template-rows: 40px auto 0px 40px;
  }

  @media (max-width: ${size.sm}) {
    padding: 22px 32px 16px 24px;
    grid-template-rows: 40px auto 0px 40px;
  }

  @media (max-width: ${size.xs}) {
    padding: 0px 32px 8px 16px;
    height: 186px;
    gap: 8px;
  }

  & ${Text.Average} {
    z-index: 2;
    font-weight: 500;
    color: #011632;
  }

  & ${CardTitle} {
    gap: 0 !important;
  }

  & ${Button.NewPrimary} {
    height: 40px;
    z-index: 2;
    width: 94px;
  }

  & ${Price} {
    @media (max-width: ${size.sm}) {
      font-size: 18px;
      line-height: 26px;
    }

    @media (max-width: ${size.xs}) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  & ${Frame} {
    ${frameCSS}
  }
`

export const ImportantBlock = styled(({ className }) => (
  <div className={className}>
    <Text.Large>
      <LigthningSVG />
      Важно
    </Text.Large>
    <Text.Large>
      Психотерапия даст результат, только если человек готов к работе. Чтобы
      подарок принёс пользу и не ранил, дарите сертификат только тому, кто хочет
      обратиться к психологу.
      <br />
      <br /> Онлайн-консультации не подходят людям в критическом состоянии — в
      таком случае стоит обратиться к врачу-психиатру.
    </Text.Large>
  </div>
))`
  display: flex;
  flex-direction: column;
  justify-content: center:
  align-items: center;
  border: 1px solid #DEDEDE;
  padding: 40px;
  background-color: ${color.background};
  border-radius: 24px;
  width: 66%;
  margin: 0 auto;

  @media (max-width: ${size.lg}) {
    padding: 32px;
    width: 75%;
  }

  @media (max-width: ${size.md}) {
    padding: 24px;
    width: 82%;
  }

  @media (max-width: ${size.sm}) {
    padding: 24px;
    width: 100%;
  }

  @media (max-width: ${size.xs}) {
    padding: 16px;
  }

  & > ${Text.Large} {
    text-align:  center;
  }

  & > ${Text.Large}:nth-child(1) {
    color: #2963A3;
    font-weight: 600;
    margin-bottom: 16px;

    & svg {
      margin-right: 16px;
    }

    @media (max-width: ${size.xs}) {
      margin-bottom: 8px;
    }
  }
`
