import { getUTM } from '../atoms/initialQueryParams'
import { useLoggedUserData } from './useLoggedUserData'
type UserUtm = {
  utm_source?: string
}
export const useUserUtm = (): UserUtm => {
  const [userData] = useLoggedUserData()
  if (userData?.analyticsFields?.utmSource) {
    return {
      utm_source: userData.analyticsFields.utmSource
    }
  }
  const utm = getUTM()
  return {
    utm_source: utm?.utm_source
  }
}
